.App {
  /*text-align: center;*/
  
}

.App .container.mt-5 {margin-top: 80px!important;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  font-size: 1.2rem;
  color: #314C98;
}

.App-link, label {
  color: #314C98;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary {background-color: #314C98!important; border: 1px solid #314C98!important;}
.border-selected {border: 4px solid #314C98!important;}
.btn-link {color: #314C98!important;}
.btn-file input[type="file"] { position: absolute; top: -1000px; }
.containerxl .row {margin: 0;}

.color-primary {color: #314C98!important;}

.fullscreen {position: absolute; top: 0px; left: 0px; width: 100%; min-height: 100vh; background-color: #FFF; z-index: 10;}
.z-top {z-index: 1050;}

.modal {z-index: 1051!important;}
.modal-header, .modal-footer {padding: 0.25rem 1rem!important;}
.modal-title {color: #314C98; }
.modal-dialog .modal-content {width: 100%;}
fieldset {border: 1px solid #dee2e6!important; border-radius: 3px; padding: 0.5rem!important;}
fieldset legend {color: #314C98; font-size: 1.2rem; }

.pos-relative {position: relative;}
.pos-top-right {position: absolute; top: 0px; right: 0px;}
.pos-right {position: absolute; right: 10px; top: 50%; transform: translate(0, -50%);}
.btn-outil {padding: 2px 5px!important;}
.btn-outil img {max-width: 32px;}
.puce-notification {position: absolute; top: -10px; right: 0; background-color: #314C98; color: #FFF; padding: 3px; border: 2px solid #FFF; border-radius: 50%;}

.myloader {position: fixed; top: 50vh; left: 50vw; transform: translate(-50%, -50%); z-index: 1051; }

.headScreen h1 {text-align: center;}
.full-screen {min-height: 100vh; width: 100vw; position: absolute; top: 0; left: 0; background-color: #FFF;}

.headScreen, .footScreen {background-color: #F5F5F5;}
.headScreen::after {content: ''; width: 100%; height: 1px; background: transparent url(images/borderBR.png) top center no-repeat; background-size: cover;}

.headScreen h1 {margin-bottom: 0;}
.headScreen .btn-link {text-decoration: none; line-height: 1.9rem;}

.footerToolbar {position: fixed; bottom: 0; left: 0; width: 100%; background-color: #FFF; z-index: 100;}
.footerToolbar::before {content: ''; width: 100%; height: 1px; background: transparent url(images/borderBR.png) top center no-repeat; background-size: cover; display: block;}
.footerToolbar .btn-link, .footerToolbar .btn-link:hover {/*padding: 1rem;*/ font-size: 1.2rem; border-radius: 0; border-bottom: 3px solid #314C98;}
.footerToolbar .btn-link.text-secondary {border-bottom: 2px solid transparent}
.onglet, .content-liste {padding-bottom: 60px; padding-top: 60px;}
.content-screen {padding-bottom: 40px;}
.card-body h2 {font-size: 1.5rem;}
.card .card-body.row .rounded-pill {width: auto;}

#maindashboard {min-height: calc(100vh - 130px)}
.confirm-alert {z-index: 1100!important;}

.synchro_status {position: absolute; top: 65px; width: 65px; height: 65px; background-color: #314c98; color: #FFF; font-size: 2.5rem; text-align: center; border: 3px solid #ddd; border-radius: 10px; }
.synchro_bar {position: fixed; opacity: 0; top: 0px; left: 50%; width: auto; padding: 5px 20px; background-color: #314c98; color: #FFF; font-size: 1.5rem; border: 3px solid #ddd; border-radius: 0 0 10px 10px; transform: translateX(-50%); z-index: 1;transition: top 2s, opacity 3s;}
.synchro_bar.active {opacity: 1; top: 55px; transition: top 0.5s;}
@media(max-width: 768px) {
  .synchro_bar {font-size: 0.8rem; }

}
.lineEmptyTab {height: calc(100vh - 180px); vertical-align: middle; text-align: center;}
ul.liste {padding: 0;}
ul.liste-incluse li, table.liste-incluse td, table.liste-incluse th, .table-striped.liste-incluse>tbody>tr:nth-of-type(odd)>* {font-size: 1.0rem; color: #314C98;}
ul.liste-incluse li label {text-decoration: underline;}

.alternate-color {padding: 5px 12px;}
li.alternate-color {list-style-type: none; padding: 0;}
.alternate-color:nth-child(odd) {background-color: #f4f5f5; border-radius: 5px;}

.liste-settings li {position: relative;}

.liste-photo {padding: 0;}
.line-photo {position: relative; padding: 0; list-style-type: none;}
.line-photo p {font-size: 1.0rem; color: #333;}
.btn-delete-line {position: absolute; top: -7px; right: 5px; display: inline-block; width: auto; width: 20px; height: 20px; background-color: #FFF; color: #d13129; border: 2px solid #d13129; border-radius: 50%; line-height: 10px; padding: 0; text-align: center; font-size: 0.8rem;}

.btn-memo {position: fixed; top: 15%; right: 5px; z-index: 5;}

.editPhoto {min-height: 250px;}
.tool-btn-bottom {position: absolute; bottom: 0px; width: 100%; }
.tool-btn-bottom .btn-group {border-radius: .375rem .375rem 0 0;}
.tool-btn-bottom .btn, .tool-btn-bottom .btn:hover {color: #314c98; border-bottom-left-radius: 0; border-bottom-right-radius: 0;}
.tool-btn-bottom .btn-light {background-color: rgba(248,249,250,0.7); border-color:rgba(248,249,250,0.7)!important; }
.tool-btn-bottom .btn-lg {font-size: 2rem; padding-left: 25px; padding-right: 25px;}

#btnCamera, #btnGalerie {font-size: 0.7rem;}
#btnCamera svg, #btnGalerie svg{font-size: 2.0rem;}

.modalSites .modal-content {margin: 0;}
.modalCamera .modal-content {margin: 0; max-height: unset;}

.font-sm {font-size: 0.8rem;}
@media(max-width: 767px) {
  .font-mobile-sm {font-size: 0.8rem;}
}

.previewRemark {max-width: 80px; max-height: 150px;}

.sigCanvas {border: 1px solid #444; width: 100%;}
.pasSignature {line-height: 100px; font-size: 0.8rem; color: #CCC; text-align: center; width: 100%;}

#toast_memo {position: fixed; top: calc(15% - 5px); width: 50%; right: 0px; z-index: 100;}
#toast_memo .pjmemos img {max-width: 100px; max-height: 100px; width: auto; height: auto; border: 1px solid #314C98}
#toast_memo .pjmemos svg {font-size: 3em; margin-top: 20px; display: block;}
@media(max-width: 768px) {
  #toast_memo {width: 100%;}
}

ul.select-search-options {padding-left: 0;}

.offline-ico {position: relative; display: inline-block; font-size: 1.2rem; margin-left: 15px;}
.offline-ico::after {content:''; width: 120%; height: 3px; background-color: #314C98; transform: rotate(-45deg); display: inline-block; position: absolute; left: -3px; top: 7px;}